<template>
  <div :class="{ 'w-banner': lowerContent }">
    <Header v-if="!$route.path.startsWith('/f/') && !$route.query.screenshot" />
    <main :class="[$config.platform, { qa: $route.name === 'qa-hub' || $route.name === 'question-question', 'flashcards-main': $route.path.startsWith('/flashcards') }]">
      <Banner
        v-if="($store.getters['banners/promoBanner'] || $store.getters['banners/activeTopBanner']) && showBanner" class="top-banner" :class="$config.project"
        :banner-type="[2]"
      />
      <transition>
        <Nuxt />
      </transition>
      <CookiePolicy />
    </main>
    <client-only>
      <Component
        :is="component"
        v-for="{ component, key, props } in modals"
        :key="key"
        :class="$config.platform"
        v-bind="props"
      />
    </client-only>
    <Footer v-if="!$route.query.screenshot" />
  </div>
</template>

<script>
import global from '@itbpbg/lms-components/src/mixins/global';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookiePolicy from '../components/CookiePolicy';
import {
  TITLES, BANNER_FORBIDDEN_ROUTES, CAMPAIGN_PAGES, SIGNUP_PAGES,
} from '../utils/constants';

export default {
  components: {
    Header,
    Footer,
    Banner: async () => import('@itbpbg/lms-components/src/Banner'),
    CookiePolicy,
  },
  mixins: [global],
  data() {
    return {
      currentPlanPrice: null,
      lottiePlugin: null,
    };
  },
  head() {
    let bodyAttrs = {};
    if (this.$route.path === '/checkout/') {
      bodyAttrs = {
        class: 'checkout-tidio',
      };
    } else if (this.$route.name === 'courses-course-lecture') {
      bodyAttrs = {
        class: 'course-lecture',
      };
    }

    const scripts = [];
    if (this.$route.path.includes('flashcards')) {
      scripts.push(
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML', async: true },
      );
    }

    return {
      titleTemplate: `${TITLES[this.$route.name] || ''}%s`,
      bodyAttrs,
      script: scripts.length ? scripts : null,
    };
  },
  computed: {
    showBanner() {
      if (this.$store.state.modals.opened.some((modal) => modal.name === 'gallery')) {
        return false;
      }

      if (this.$route.name === 'pricing' && this.$store.state.campaign === 'campaign-chat-gpt') {
        return true;
      }

      if (BANNER_FORBIDDEN_ROUTES.some((r) => this.$route.path.includes(r))) {
        return false;
      }

      if (this.$route.name === 'resources-center-category-slug' && this.$route.query.preview) {
        return false;
      }

      if (this.$store.getters['auth/user'].activeSubscriptionPlan || this.$store.getters['auth/user'].activeSubscriptionPlan === 0) {
        const monthlyPlanExtend = this.$store.getters['auth/user'].activeSubscriptionPlan === 0 && (this.$store.getters['subscriptions/monthly'].discountPrice < this.currentPlanPrice || this.$store.getters['banners/promoBanner']);
        const quarterlyPlanExtend = this.$store.getters['auth/user'].activeSubscriptionPlan === 1 && (this.$store.getters['subscriptions/quarterly'].discountPrice < this.currentPlanPrice || this.$store.getters['banners/promoBanner']);
        const annualPlanDiscountExtend = this.$store.getters['auth/user'].activeSubscriptionPlan === 2 && this.$store.getters['subscriptions/annual'].discountPrice < this.currentPlanPrice;

        return monthlyPlanExtend || quarterlyPlanExtend || annualPlanDiscountExtend;
      }

      return true;
    },
    modals() {
      return this.$store.getters['modals/opened'];
    },
    lowerContent() {
      return (this.$store.getters['banners/promoBanner'] || this.$store.getters['banners/activeTopBanner']) && this.showBanner && this.$store.getters['auth/user'].role !== 'business-student' && this.$store.getters['auth/user'].role !== 'business-admin';
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (oldValue.path === '/checkout/' && newValue.path === '/') {
        this.$store.dispatch('banners/fetch');
      }

      if (!CAMPAIGN_PAGES.includes(newValue.path)) {
        if (this.$config.platform === 'ds') {
          this.$loadScript('https://code.tidio.co/gk4odtftsf34mtfsmztbq1dsh0co0ewr.js');
        } else if (this.$config.platform === 'fa') {
          this.$loadScript('https://code.tidio.co/7zfnadtyzcnk84v5ujlkxhqapyy084oa.js');
        }
      }

      if (SIGNUP_PAGES.includes(newValue.path) || newValue.path.includes('flashcards')) {
        this.$loadScript('https://accounts.google.com/gsi/client', { async: true });
      }

      this.checkLmsAccess();
      setTimeout(() => {
        this.$tracking.setTrackingSections();
      }, 2000);
    },
  },
  async created() {
    if (process.client) {
      if (this.$route.path !== '/checkout/' && (this.$store.state.campaign)) {
        await this.$store.dispatch('banners/fetch');
      }
      if (this.$store.getters['auth/user'].id && this.$store.getters['banners/promoBanner']) {
        await this.$store.dispatch('subscriptions/getUserSubscriptions');
        this.currentPlanPrice = this.$store.getters['subscriptions/active'].priceWoutTaxes;
      }
      if (window.location.href.indexOf('login?code=') === -1) {
        this.$gtag.set({
          referrer: referrerOrigin,
        });
      }

      this.checkLmsAccess();
      window.addEventListener('click', (e) => {
        this.$root.$emit('emit-click', e);
      });

      window.addEventListener('resize', this.onResize);
      this.$store.commit(
        'setLayout',
        window.innerWidth < this.$store.state.breakPointLayoutResolution
          ? 'mobile'
          : 'desktop',
      );

      this.$store.commit(
        'setLayoutTablet',
        window.innerWidth < this.$store.state.breakPointLayoutResolution && window.innerWidth > 600,
      );

      this.$store.commit(
        'setLayoutLaptop',
        window.innerWidth >= this.$store.state.breakPointLayoutResolution && window.innerWidth < 1440,
      );

      window.addEventListener('scroll', this.handleScroll, this.$eventPassive);
      window.addEventListener('click', this.ctaClickEvent);
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('click', this.ctaClickEvent);
      });
    }
  },
  mounted() {
    if (!CAMPAIGN_PAGES.includes(this.$route.path)) {
      document.addEventListener('DOMContentLoaded', () => {
        if (this.$config.platform === 'ds') {
          this.$loadScript('https://code.tidio.co/gk4odtftsf34mtfsmztbq1dsh0co0ewr.js');
        } else if (this.$config.platform === 'fa') {
          this.$loadScript('https://code.tidio.co/7zfnadtyzcnk84v5ujlkxhqapyy084oa.js');
        }
      });
    }

    if (SIGNUP_PAGES.includes(this.$route.path) || this.$route.path.includes('flashcards')) {
      this.$loadScript('https://accounts.google.com/gsi/client', { async: true });
    }
  },
  methods: {
    ctaClickEvent($event) {
      const elementClasses = [...$event.target.classList];
      const regex = /subscribe-(to-)?(annual|monthly|quarterly|forever|lifetime)/;
      if (elementClasses.some((c) => regex.test(c))) {
        const eventID = `${this.$store.state.tracking.sessionId}${Date.now()}`;
        dataLayer.push({ event: 'CTA-click', eventID });
        this.$tracking.sendEvent({
          dataLayerEvent: 'CTA-click',
          destinationUrl: this.$router.currentRoute.fullPath,
          eventID,
          capi: true,
          userAgent: navigator.userAgent,
        });
      }
    },
    onResize() {
      // Mobile / Desktop switch
      if (
        (this.$store.state.layout === 'desktop'
          && window.innerWidth < this.$store.state.breakPointLayoutResolution)
        || (this.$store.state.layout === 'mobile'
          && window.innerWidth >= this.$store.state.breakPointLayoutResolution)
      ) {
        this.$store.commit(
          'setLayout',
          this.$store.state.layout === 'mobile' ? 'desktop' : 'mobile',
        );
      }

      // Tablet switch
      if (
        (window.innerWidth < this.$store.state.breakPointLayoutResolution && window.innerWidth > 600 && !this.$store.state.tablet)
      || ((window.innerWidth >= this.$store.state.breakPointLayoutResolution || window.innerWidth <= 600) && this.$store.state.tablet)) {
        this.$store.commit(
          'setLayoutTablet',
          !this.$store.state.tablet,
        );
      }

      // Laptop switch
      if (
        (window.innerWidth >= this.$store.state.breakPointLayoutResolution && window.innerWidth < 1440 && !this.$store.state.laptop)
      || ((window.innerWidth < this.$store.state.breakPointLayoutResolution || window.innerWidth >= 1440) && this.$store.state.laptop)) {
        this.$store.commit(
          'setLayoutLaptop',
          !this.$store.state.laptop,
        );
      }
    },
    async handleScroll() {
      const elements = this.$el.querySelectorAll('.lottie-animation');
      if (elements && elements.length) {
        if (!this.lottiePlugin) {
          this.lottiePlugin = (await import('lottie-web')).default;
        }

        const registeredAnimation = this.lottiePlugin.getRegisteredAnimations();
        if (elements.length) {
          elements.forEach((el) => {
            const offset = el.getBoundingClientRect();
            if (el.classList.contains('play-on-scroll') && !el.classList.contains('played') && offset.top - window.innerHeight * 0.9 < 0) {
              const animation = registeredAnimation.filter((v) => v.animationID === el.id);
              if (animation.length) {
                animation.pop().play();
                el.classList.add('played');
              }
            }
          });
        }
      }

      const footer = this.$el.querySelector('footer');
      if (footer) {
        const footerBound = footer.getBoundingClientRect();
        if (footerBound.top - footerBound.height < 0) {
          this.$store.dispatch('dataLayer/addEvent', { event: 'full_scroll' });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.qa,
.flashcards-main {
  background-color: var(--main-light-shade-1);
}

main:before {
  content: '';
  display: block;
  background-color: var(--main-dark);
  height: 0;
  width: 100%;
  transition: all var(--duration) ease-in-out;
}

.w-banner {
  > .header {
    &,
    &.on-checkout {
      background-color: var(--main-dark);
    }

    &.background .logo svg.dark.svg-logo path {
      fill: #FFF;
    }
  }

  > main:before {
    height: 171px;
  }
}

.course-lecture #tidio-chat {
  bottom: 100px;
}

@include bp($bp-mobile) {
  .w-banner > main:before {
    height: 411px;
  }

  .container-wide {
    width: 100%;
    padding: 0 var(--spacing-24);
  }

  .checkout-tidio #tidio-chat {
    display: none;
  }
}

.fa.qa {
  background-color: var(--main-light-shade-2);
}
</style>
